.main {
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    225deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(161, 151, 253, 1) 62%,
    rgba(140, 128, 252, 1) 80%
  ); */

  /* background-color: #000000; */
}
.img-center {
  border-radius: 10px;
  /* border: 6px solid black; */
  width: 65rem;
}
.main-text-title {
  font-size: 40px;
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
}
.main-text-subheading {
  /* line-height: 1.4; */
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
}
/* button part  */

.title-main {
  color: #ae9cda;
}

a {
  text-decoration: none;
  color: #6be5aa;
}
a:hover {
  color: #aeaed6;
}

.left-btn {
  background: linear-gradient(76.91deg, #b818ff, #9313cc);
  border: none;
  padding: 13px 36px;
  color: white;
  /* text-transform: uppercase; */
  font-weight: 800;
  font-size: 15px;
  transition: 1s;
}
/* button hover  */
.left-btn:hover {
  box-shadow: 0.2rem 0.2rem #cf64ff, -0.2rem -0.2rem #9313cc;
  /* background: rgb(252, 76, 143); */
}
.opensea:hover {
  background-color: rgb(255, 91, 91);
}
.bottom-text {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 600;
  color: #363636;
}
.main-bottom-buttom {
  margin-bottom: 0.5rem;
}

.main-link:hover {
  color: #5b4ffa;
}

/* responsive for mobile screen  */
@media (max-width: 520px) {
  .img-center {
    width: 25rem;
  }
}
@media (max-width: 405px) {
  .img-center {
    width: 23rem;
  }
  .title-main {
    font-size: 40px;
  }
}
@media (max-width: 380px) {
  .img-center {
    width: 20rem;
  }
}
@media (max-width: 330px) {
  .img-center {
    width: 17rem;
  }
}

@media (max-width: 990px) {
  .main {
    height: auto;
  }
}
