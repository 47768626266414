.navbar {
  background-color: #000000 !important;
}
.navbar-max {
  width: 1340px !important;
}
/* Remove border from toggler */
.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

/* Lines of the Toggler */
.toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #494241;

  display: block;
  transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar {
  margin: 5px auto;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
  background-color: #777777;
}

.icon-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
}
.icon-box:hover {
  background-color: hsl(236, 50%, 32%);
  color: white;
}
.fa-discord,
.fa-twitter,
.fa-instagram {
  font-size: 15px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nav-link {
  color: white;
  font-weight: 700;
  border-bottom: 2px solid transparent;
  padding: 2px 10px;
  font-size: 15px;
  transition: 0.2s ease-out;
}
.active {
  color: #19ff45;
}

.logo-text {
  font-size: 38px;
  color: #5b4ffa;
  font-weight: 800;
}
/* nav item  */

.nav-link:hover {
  color: #5b4ffa !important;
}

.social-icon {
  font-size: 23px;
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  transition: 0.9;
}

.social-icon:hover {
  /* color: #5b4ffa; */
  opacity: 0.5;
  /* transform: scale(1.2); */
}
