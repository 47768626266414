.team {
  background-color: #000000;
  /* background-color: #4136dd; */
  /* background-color: #4238d1; */
}

.img-team {
  /* width: 200px; */
  /* height: 200px; */
  border-radius: 100%;
  background-color: #ffffff;
  /* background-color: rgb(216, 71, 71); */
  border: 4px solid black;
  cursor: pointer;
  transition: 00.2s ease-out;
}

.img-team:hover {
  background-color: #5b4ffa;
  /* transform: scale(1.1); */
}

.title-team {
  font-size: 40px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}
.sub-title-team a {
  font-size: 25px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s ease-out;
}

.sub-title-team a:hover {
  color: #5b4ffa;
}

.team-description {
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}
/* .title {
  font-size: 33px;
  color: #ffffff;
  font-weight: 700;
} */
/* .description {
  color: white;
} */

@media (max-width: 500px) {
  .sub-title-team a {
    font-size: 18px;
  }
}
