.accordion-item {
  background-color: #000000;
}
.title-faq {
  color: white;
  font-weight: 800;
  font-size: 40px;
}
.faq-button {
  color: white;
  cursor: pointer;
  font-weight: 700;
  border-radius: 15px !important;
  font-size: 15px;
  background-color: #000000;
}
.accordion-button:not(.collapsed) {
  color: #ffffff;
  font-weight: 700;
  background-color: #000000;

  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.accordion-button.collapsed {
  color: white;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-body {
  color: white;
}
.faq {
  /* margin-top: 6rem; */
  background-color: #000000;
}
.accordion-body {
  font-size: 15px;
  font-weight: 600;
  color: white;
}
.fa-minus {
  font-size: 30px;
  color: white;
  font-weight: 700;
}
@media (max-width: 500px) {
  .title-faq {
    font-size: 40px;
  }
  .accordion-body {
    font-size: 13px;
  }
}
