.groups {
  background-color: #601a7300;
}

.whitelist {
  background-color: #000000;
}
.box {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.timeline-content {
  background-color: #770fd37a;
  padding: 20px;
  border-radius: 10px;
  text-align: justify;
}
.timeline {
  width: 80%;
  height: auto;

  margin: 0 auto;
  position: relative;
}

.timeline ul {
  list-style: none;
}
.timeline ul li {
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}
.timeline ul li:last-child {
  margin-bottom: 0;
}
.timeline-content h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #4a1666;
  /* color: rgb(255, 135, 135); */
}

.timeline-content p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
.timeline-content .date {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
.subtitle {
  /* color: #000; */
  color: #ffffff;
  font-size: 35px;
  /* text-transform: uppercase; */
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background: linear-gradient(
      76.91deg,
      rgb(255, 255, 255) -125.56%,
      rgb(255, 255, 255) 116.11%
    );
  }

  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: 80px;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-60px);
  }
  .timeline ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
  }
  .timeline ul li::before {
    content: "";
    position: absolute;
    height: 25px;

    width: 25px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    top: 0px;
  }
  .timeline ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -44px;
    top: 50%;
  }
  .timeline ul li:nth-child(even)::before {
    transform: translate(-50%, -50%);
    left: -45px;
    top: 50%;
  }
  .timeline-content .date {
    position: absolute;
    top: -30px;
  }
  .timeline ul li:hover::before {
    background-color: rgb(255, 255, 255);
  }
}
@media (max-width: 750px) {
  ul {
    padding-left: 0.1rem;
  }
}
