.animation-part {
  background-color: #000000;
  /* background-color: #8c80fc; */
}

.tech-slideshow {
  height: 100px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.tech-slideshow2 {
  height: 100px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.container-fluid {
  padding: 0px !important;
}
.tech-slideshow > div {
  height: auto;
  width: 25260px;
  background: url(../Image/LACband1.png);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
.tech-slideshow2 > div {
  height: auto;
  width: 25260px;
  background: url(../Image/LACband2.png);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
.tech-slideshow .mover-1 {
  animation: moveSlideshow 90s linear infinite;
}
.tech-slideshow2 .mover-2 {
  animation: moveSlideshow2 90s linear infinite;
}

.tech-slideshow:hover .mover-2 {
  opacity: 1;
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}
@keyframes moveSlideshow2 {
  100% {
    transform: translateX(66.6666%);
  }
}
