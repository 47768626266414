.safety {
  background-color: #000000;
}
.safety-title {
  font-size: 40px;
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
}
.safety-link {
  color: #ffffff;
}
.safety-link:hover {
  color: #5b4ffa;
}
